import React from "react";
// import { Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/layout";
// import { normalizePath } from "../../utils/get-url-path";

function DefaultPage({ data }) {
  // const { nextPage, previousPage, page } = data;
  const { page } = data;
  const { title, content, featuredImage } = page;

  return (
    <Layout>
      <div className="Page">
        <h1 className="Page-title">{title}</h1>

        {!!featuredImage?.node?.remoteFile?.childImageSharp && (
          <div>
            <Img fluid={featuredImage.node.remoteFile.childImageSharp.fluid} />
          </div>
        )}

        <div
          className="Page-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />

        {/* 
        {!!nextPage && (
          <Link to={normalizePath(nextPage.uri)}>Next: {nextPage.title}</Link>
        )}

        {!!previousPage && (
          <Link to={normalizePath(previousPage.uri)}>
            Previous: {previousPage.title}
          </Link>
        )} */}
      </div>
    </Layout>
  );
}

export default DefaultPage;
